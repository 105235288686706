export const navigationItems = [
    { href: "/", label: "Home" },
    { href: "/post-create", label: "Post Create" },
    { href: "/category/create", label: "Category Create" },
    { href: "/admin-panel", label: "Admin Panel" },
    { href: "/me", label: "Panel" },
    { href: "/login", label: "Login" },
    { href: "/sing-up", label: "Sign up" },
];

