
import NewPasswordSetPanel from "../../components/ResetPasswordPanel/new_password";
function SetNewPasswordDisplay(){
    return (
        <>
            <NewPasswordSetPanel />
        </>
    )
}

export default SetNewPasswordDisplay;