import styled from "styled-components";

/*
    Function and components responsible for entire ui
 */

export const MainContainer = styled.div`
    //margin: 0 auto;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    //max-width: 100%;
    //height: 100%;
    
`;



